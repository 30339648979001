// TODO: <reference types... webpack-hot-interface"> errors after upgrade to Webpack 5.x. How to re-enable this?
// /// <reference types="aurelia/loader-webpack/src/webpack-hot-interface" />

import { AureliaConfiguration } from 'aurelia-configuration';
import { Aurelia, Container, LogManager } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { env } from 'process';
import type from '../config/environment.json';
// import Bluebird from 'bluebird';
import { FontAwesomeConfiguration } from 'infra/font-awesome-configuration';
import { GlobalResources } from 'infra/global-resources';
import { MomentConfiguration } from 'infra/moment-configuration';
import { RollbarAppender } from 'infra/rollbar-appender';
import { ToastrConfiguration } from 'infra/toastr-configuration';
import { RollbarService } from 'services/rollbar-service';
import { LocalStorageHelper, LocalStorageKeys } from 'utilities/local-storage-helper';
import {
    AuditLogsApiClient,
    BusinessMapsApiClient,
    CasesApiClient,
    CompaniesApiClient,
    CounterMeasuresApiClient,
    DetectionsApiClient,
    CyberConfig,
    InvestigationsApiClient,
    LogsApiClient,
    NotificationConfigurationApiClient,
    NotificationsApiClient,
    ReportsApiClient,
    StatsApiClient,
    ThreatsApiClient,
    SicApiClient,
    XchangeApiClient,
    ZonesApiClient,
    ActivityLogsApiClient,
    EdrApiClient,
    UserProfileApiClient
} from './services/cyber-api';

// Promise configuration, see http://bluebirdjs.com/docs/api/promise.config.html
// Disables forgotten return warnings caused by aurelia-bootstrapper.js
// Remove if you don't want a Promise polyfill (also remove from webpack.config.js)
// Bluebird.config({
//     warnings: { wForgottenReturn: false }
// });

export async function configure(aurelia: Aurelia): Promise<void> {
    aurelia.use
        .standardConfiguration()
        .feature(PLATFORM.moduleName('resources/index'))
        .plugin(PLATFORM.moduleName('aurelia-configuration'))
        .plugin(PLATFORM.moduleName('aurelia-animator-css'))
        .plugin(PLATFORM.moduleName('aurelia-ui-virtualization'))
        .globalResources(GlobalResources.resources);

    // Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
    // aurelia.use.plugin(PLATFORM.moduleName('aurelia-html-import-template-loader'));

    // load environment variables into process
    env.debug = String(type.debug);
    env.testing = String(type.testing);
    if (env.debug === 'true')
        aurelia.use.developmentLogging();

    if (env.testing === 'true')
        aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));

    const config = new AureliaConfiguration();
    await config.loadConfig();
    // Set up Rollbar logging
    if (config.get('rollbar.enabled')) {
        const rollbar = new RollbarService();
        rollbar.configure(
            config.get('rollbar.accessToken'),
            config.get('environment.name'),
            config.get('version')
        );
        LogManager.addAppender(new RollbarAppender(rollbar));

        // Register the rollbar instance as a singleton so the AuthenticationProvider will have the same instance
        // later in the pipeline
        aurelia.container.registerSingleton(RollbarService, () => rollbar);
    }

    // Register global DI instances, for example for CyberApi clients and CyberConfiguration
    configureGlobalDependencyInjection(aurelia.container, config);

    // Configure FontAwesome
    FontAwesomeConfiguration.configure();
    MomentConfiguration.configure();
    ToastrConfiguration.configure();

    await aurelia.start();
    await aurelia.setRoot(PLATFORM.moduleName('app'));
}

function configureGlobalDependencyInjection(container: Container, settings: AureliaConfiguration): void {
    const endpoint = settings.get('credentials.cyberApiEndpoint');

    const types = [
        ActivityLogsApiClient,
        AuditLogsApiClient,
        BusinessMapsApiClient,
        CasesApiClient,
        CompaniesApiClient,
        CounterMeasuresApiClient,
        DetectionsApiClient,
        InvestigationsApiClient,
        LogsApiClient,
        NotificationsApiClient,
        NotificationConfigurationApiClient,
        ReportsApiClient,
        StatsApiClient,
        ThreatsApiClient,
        SicApiClient,
        XchangeApiClient,
        ZonesApiClient,
        EdrApiClient,
        UserProfileApiClient
    ];
    types.forEach((t) => {
        container.registerTransient(t, () => new t(getCyberConfig(), endpoint));
    });
}

function getCyberConfig(): CyberConfig {
    return {
        authToken: LocalStorageHelper.get<string>(LocalStorageKeys.AccessToken)
    } as CyberConfig;
}
