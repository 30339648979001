export enum ThreatAction {
    Close = 'Threats_Close',
    Ignore = 'Threats_Ignore',
    Flag = 'Threats_Flag',
    Unflag = 'Threats_Unflag',
    AddComment = 'Threats_AddComment',
    AddToCase = 'Cases_AttachEntity',
    RemoveFromCase = 'Cases_DetachEntity',
    ExecuteDetection = 'Detections_Execute',
    ExecuteAction = 'Edr_QuarantineAction',
}
