import { autoinject } from 'aurelia-dependency-injection';
import { NavigationInstruction, Next, Redirect } from 'aurelia-router';
import { AuthenticationProvider } from 'providers/authentication-provider';
import { CompaniesApiClient, CompanyLightweight } from 'services/cyber-api';
import { StateApi, StateKeys } from 'services/state-api';
import { LocalStorageHelper, LocalStorageKeys } from 'utilities/local-storage-helper';
import { EventAggregator } from 'aurelia-event-aggregator';
import { EventKeys } from '../enums/event-keys';
import Swal from 'sweetalert2';

declare var ga: any;

/**
 * The AuthorizeStep can be injected in Aurelia's RouterConfiguration
 * to be executed on each route request.
 */
@autoinject()
export class AuthorizeStep {
    constructor(
        private auth: AuthenticationProvider,
        private state: StateApi,
        private companiesApi: CompaniesApiClient,
        private eventAggregator: EventAggregator
    ) {
    }

    /**
     * Handles authentication for a navigation instruction.
     * @param navigationInstruction The navigation instruction to handle authentication for.
     */
    private async run(navigationInstruction: NavigationInstruction, next: Next): Promise<any> {
        this.setAuthReturnUrl(navigationInstruction);

        const allInstructions = navigationInstruction.getAllInstructions();

        // verify: if route (page) allows anonymous access, allow it without authenticating
        if (allInstructions.some((instruction) => instruction.config.settings && instruction.config.settings.allowAnonymous))
            return await next();

        // verify: if user is already authenticated, all is well
        if (this.auth.isAuthenticated()) {
            // Most test users may only have access to a fixed company, set it to enforce that company for the user
            const company = await this.setUserCompany(this.auth.profile.sub);

            // verify: if company is allowed to see this page
            const enableNavigation = (nav: NavigationInstruction): boolean => {
                const isEdr = nav.config.settings.isEdr;
                const edrActive = company.isEdrActive;
                const isThreatDetection = nav.config.settings.isThreatDetection;
                const threatDetectionActive = company.isThreatDetectionActive;

                if (isEdr && isThreatDetection) {
                    return edrActive || threatDetectionActive;
                } else if (isEdr) {
                    return edrActive;
                } else if (isThreatDetection) {
                    return threatDetectionActive;
                }
                return true;
            }

            if (!enableNavigation(allInstructions.find(x => !x.config.hasChildRouter))) {
                if (company.isThreatDetectionActive) {
                    return await next.cancel(this.edrAd(allInstructions.find(x => !x.config.hasChildRouter)));
                } else {
                    return await next.cancel(this.cyberAd(allInstructions.find(x => !x.config.hasChildRouter)));
                }
            }
            return await next();
        }

        // All other cases, simply return false
        return await next.cancel(this.auth.login());
    }

    private edrAd(navigationInstruction: NavigationInstruction) {
        Swal.fire({
            width: '40rem',
            html: `
            <h3><span class="text-primary">Cyber</span>Guard <span class="text-primary">EDR (End Point Security)</span></h3>
            <div style="text-align:left" class="pt-2">
                <p>
                  Protect your business computers from harmful malware thanks to our advanced Endpoint Detection & Response (EDR) solution. CyberGuard EDR is optimised for the satellite communications environment and built on the following modules:
                </p>
                <p>
                  <span class="text-primary font-weight-bold">Malware Protection</span> <strong>Signature-based</strong> malware detection
                </p>
                <p>
                  <span class="text-primary font-weight-bold">MalwareGuard</span> <strong>Machine Learning based</strong> malware detection
                </p>
                <p>
                  <span class="text-primary font-weight-bold">ExploitGuard</span> <strong>Behavior-based</strong> malware detection
                </p>
                <p>
                  <span class="text-primary font-weight-bold">MalwareScan</span> <strong>Scan USB device</strong> on insert
                </p>
                <br/>
                <p>
                  <strong>For more information, please see the <a class="text-primary" href="/assets/documents/CyberGuard-EDR-Info-Sheet-2024.pdf" target="_blank">CyberGuard EDR Info Sheet <i class="far fa-arrow-up-right-from-square"></i></a><br/>
                  If you are interested, please contact your sales representative / KAM</strong>
                </p>
            </div>
            `,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok, Thanks',
        });
        if (navigationInstruction.previousInstruction === null && navigationInstruction.fragment === '/fleet-health-monitor') {
            return new Redirect('/');
        }
        return;
    }

    private cyberAd(navigationInstruction: NavigationInstruction) {
        // to prevent new user seeing the alert
        if (navigationInstruction.previousInstruction === null && navigationInstruction.fragment === '/') {
            return new Redirect('fleet-health-monitor');
        }
        Swal.fire({
            width: '40rem',
            html: `
            <h3><span class="text-primary">Cyber</span>Guard <span class="text-primary">Threat Detection</span></h3>
            <div style="text-align:left" class="pt-2">
                <p>
                  The Threat Detection solution monitors all outbound and inbound vessel traffic 24/7 and consists of the following components:  
                </p>
                <p>
                  <span class="text-primary">Security Information and Event Management (SIEM):</span> detected threats are reported in the CyberGuard Portal and countermeasures may be applied remotely
                </p>
                <p>
                  <span class="text-primary">Security Operations Centre (SOC):</span> managed threat confirmation, hunting and incident support
                </p>
                <p>
                  <span class="text-primary">Managed Security Services Provider (MSSP):</span> fully Marlink-managed threat remediation
                </p>
                <br/>
                <p>
                   <strong>For more information, please see the <a class="text-primary" href="/assets/documents/CyberGuard-Threat-Det-Info-Sheet-2024.pdf" target="_blank">CyberGuard Threat Detection Info Sheet <i class="far fa-arrow-up-right-from-square"></i></a><br/>
                  If you are interested, please contact your sales representative / KAM</strong>
                </p>
            </div>
            `,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok, Thanks',
        });

        return new Redirect('fleet-health-monitor');
    }

    /**
     * Sets the company based on the authenticated user's external id
     * @param externalId User to set company for
     */
    private async setUserCompany(externalId: string): Promise<CompanyLightweight> {
        const companyState = this.state.company();

        // Retrieve companies from the state cache
        let companies = this.state.companies() || [];

        // If state does not contain any companies, retrieve them from the API
        if (companies.length === 0)
            companies = await this.companiesApi.getAll(null);

        // Check if the user has access to the company in state, if so use that company
        let company = companies.find((c) => c.id === companyState);

        // If no company was found that matches the one previously selected and now in storage, use the default (first)
        if (!company) {
            // Check to see if the user has access to 'biz1000' and assign that,
            // otherwise - take first from the list the user has access to
            const b1000 = companies.find((c) => c.id.toLowerCase() === 'biz1000');
            company = b1000 || companies[0];
        }

        // Finally set the company states, for any subsequent page navigations
        this.state.setState(StateKeys.Company, company.id);
        this.state.setState(StateKeys.CompanyName, company.name);
        this.state.setState(StateKeys.Companies, companies);

        this.eventAggregator.publish(EventKeys.onCompanySet, company);

        return company;
    }

    private setAuthReturnUrl(navigationInstruction: NavigationInstruction): void {
        // Never store any of the auth URLs. Redirecting to these after login would cause infinite-loops and other
        // strange behaviour.
        if (navigationInstruction.config.name === 'login'
            || navigationInstruction.config.name === 'login-callback'
            || navigationInstruction.config.name === 'logout')
            return;

        let authReturnUrl = navigationInstruction.fragment;
        if (navigationInstruction.queryString)
            authReturnUrl = `${authReturnUrl}?${navigationInstruction.queryString}`;

        LocalStorageHelper.set<string>(LocalStorageKeys.AuthReturnUrl, authReturnUrl);
    }
}
